import React from 'react'
import { usePageIconsImages } from 'hooks/usePageIconsImagesHook'

import Intro from 'elements/Intro'
import Goals from './Goals'

const AboutContent = ({ data }) => {
  const {
    aboutIcon: {
      childImageSharp: {
        gatsbyImageData: {
          images: {
            fallback: { src }
          }
        }
      }
    }
  } = usePageIconsImages()

  const {
    frontmatter: { title },
    html
  } = data

  return (
    <section className="py-16">
      <div className="container mx-auto px-8">
        <Intro pageTitle="About CAI" imgSrc={src} />
        <div className="mt-14 mb-20">
          <h2 className="text-4xl mb-8 font-bold">{title}</h2>
          <div className="max-w-982px text-sm" dangerouslySetInnerHTML={{ __html: html }} />
        </div>
        <hr style={{ height: '1.8px' }} className="my-28 bg-vividBlue opacity-50" />
        <Goals />
      </div>
    </section>
  )
}

export default AboutContent
