import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Accordion from 'elements/Accordion'

const Goals = () => {
  const {
    rawData: { edges: goals }
  } = useStaticQuery(query)

  return (
    <div>
      <h2 className="text-4xl mb-12 font-bold">Goals and services of the Institute</h2>
      {goals.length &&
        goals.map(({ node: { id, title, content, list } }) => {
          return <Accordion title={title} content={content} list={list} key={`${id}-${title}`} />
        })}
    </div>
  )
}

export default Goals

const query = graphql`
  query useGoals {
    rawData: allGoalsJson {
      edges {
        node {
          id
          title
          content
          list
        }
      }
    }
  }
`
