import React from 'react'
import { graphql } from 'gatsby'

import SEO from 'components/SEO/index'
import AboutContent from 'components/About'

const About = ({ data }) => {
  const { markdownRemark } = data

  return (
    <>
      <SEO title="About" />
      <AboutContent data={markdownRemark} />
    </>
  )
}

export default About

export const query = graphql`
  query About($title: String!) {
    markdownRemark(frontmatter: { title: { eq: $title } }) {
      frontmatter {
        title
      }
      html
    }
  }
`
